import React, { useState } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../layout";
import {
  mainWhite,
  mainGreen,
  mainOrange,
  montserrat,
  ccbiffbamboomRegular,
  innerWidth,
  darkBlue,
  transHover,
  screen,
  emailRegex,
} from "../components/common/variables";
import ScrollAnimation from "react-animate-on-scroll";
import { GatsbyImage } from "gatsby-plugin-image";
import SEO from "../components/seo";
import imgWallpaper from "../images/home/wallpaper-background.jpg";
import imgPaintMark from "../images/home/paint-mark.png";
import imgOrange from "../images/home/for-orange-bg.png";
import portrait from "../images/about/pronounce-lyscicrates-martin-lysicrates-prize-no-mouth.png";
import imgFeaturedBg from "../images/home/the-martin-lysicrates-prize-event.jpeg";
import imgFeaturedFixed from "../images/home/homepage-2021-banner.png";
import imgSpeechBubble from "../images/home/martin-lysicrates-learning-is-fun.png";
import portraitMouth from "../images/about/mouth-cut.png";
import SlopedLabel from "../components/SlopedLabel";
import { moveMouth } from "../components/common/animations";
import addToMailchimp from "gatsby-plugin-mailchimp";

const Wrapper = styled.div`
  font-family: ${montserrat};
  background-image: url(${imgWallpaper});
  background-repeat: repeat-y;
  background-position: center;

  .home {
    overflow: hidden;

    &__banner {
      background-color: ${mainWhite};
      background-image: ${props => `url(${props.imgBannerSmall})`};
      background-size: cover;
      background-repeat: no-repeat;
      background-position: bottom;
      padding: 75px 0 100px 0;
      @media ${screen.small} {
        background-image: ${props => `url(${props.imgBannerLarge})`};
        background-position: center;
        padding: 175px 0 200px 0;
      }
      @media ${screen.small} {
      }

      .text-banner {
        margin: 0 0 0 auto;
        color: ${mainWhite};
        max-width: ${innerWidth};
        padding-left: 30px;
        padding-right: 30px;
        max-width: 850px;
        @media ${screen.small} {
          padding-left: 50px;
          padding-right: 50px;
        }
        @media ${screen.large} {
          padding-right: 100px;
        }

        &__heading {
          font-family: ${ccbiffbamboomRegular};
          font-size: 1.6rem;
          margin: 0 auto;
          width: 100%;
          text-align: center;
          @media ${screen.small} {
            font-size: 3.2rem;
            width: auto;
            margin: 0;
            max-width: none;
            text-align: left;
          }
        }

        &__description {
          font-size: 0.9rem;
          margin: 22px 0 32px 0;
          font-weight: 700;
          font-style: italic;
          text-align: center;
          white-space: pre-wrap;
          @media ${screen.small} {
            font-size: 1.22rem;
            margin: 15px 0;
            text-align: left;
          }
          @media ${screen.medium} {
            font-size: 1.22rem;

            margin: 25px 0;
          }
        }

        &__link {
          width: 100%;
          display: block;
          text-align: center;
          margin-right: 18px;
          @media ${screen.small} {
            max-width: 552px;
            margin-right: 30px;
            text-align: right;
          }
          @media ${screen.medium} {
            max-width: 690px;
            margin-right: 100px;
          }
          @media ${screen.large} {
            text-align: left;
            margin-right: 0;
          }

          span {
            transform: rotateY(-7deg);
            transition: ${transHover};
            font-size: 1.88rem;
            @media ${screen.small} {
              font-size: 2.35rem;
            }
            @media ${screen.medium} {
              font-size: 2rem;
            }
            @media ${screen.xlarge} {
              font-size: 3rem;
            }
          }

          @media ${screen.withCursor} {
            &:hover span {
              background: ${mainWhite};
              color: ${mainOrange};
            }
          }
        }
      }
    }

    &__introduction {
      padding: 70px 25px;
      background-image: url(${imgPaintMark});
      background-repeat: no-repeat;
      background-position: 30px;
      background-size: 520px;
      @media ${screen.small} {
        padding: 100px 0;
      }

      .text-intro {
        &__heading {
          color: ${mainGreen};
          text-align: center;
          font-family: ${ccbiffbamboomRegular};
          font-size: 2.1rem;
          margin-bottom: 20px;
          @media ${screen.small} {
            font-size: 2.3rem;
          }
          @media ${screen.medium} {
            font-size: 2.5rem;
          }
        }

        &__description {
          color: ${darkBlue};
          max-width: 900px;
          margin: 0 auto;
          text-align: center;

          p {
            font-weight: 700;
            font-style: italic;
            font-size: 1.2rem;
            margin-bottom: 15px;
            @media ${screen.small} {
              font-size: 1.4rem;
              margin-bottom: 30px;
              padding: 0 50px;
            }
            @media ${screen.medium} {
              font-size: 1.55rem;
              padding: 0;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    &__stream {
      padding: 70px 25px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      background-image: url(${imgPaintMark});
      background-repeat: no-repeat;
      background-position: 30px;
      background-size: 520px;
      @media ${screen.small} {
        padding: 100px 60px;
      }

      &__heading {
        color: ${darkBlue};
        text-align: center;
        font-family: ${ccbiffbamboomRegular};
        font-size: 2.1rem;
        @media ${screen.small} {
          font-size: 2.3rem;
        }
        @media ${screen.medium} {
          font-size: 2.5rem;
        }
      }

      &__watching {
        width: 100%;
        margin-top: 60px;

        position: relative;
        padding-top: calc(100% / 21 * 9);

        > iframe {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }

        > img {
          width: 25%;

          position: absolute;
          bottom: -20%;
          left: -10%;
        }
      }

      &__meeting {
        margin-top: 50px;

        > p {
          width: 100%;
          text-align: center;
          color: ${darkBlue};
          font-weight: 400;
          font-size: 1.6rem;
          font-weight: 700;
          font-style: italic;
          margin: 15px 0;
          @media ${screen.small} {
            font-size: 1.8rem;
          }
          @media ${screen.medium} {
            font-size: 2rem;
          }
        }
        &__items {
          display: flex;
          justify-content: center;
          gap: 0 10%;

          > div {
            width: min(calc(80% / 3), 300px);
            margin-top: 32px;

            > div {
              position: relative;
              overflow: hidden;
              ::after {
                content: "";
                display: block;
                padding-bottom: 100%;
              }

              > img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            > p {
              text-align: center;
              font-weight: 600;
              font-size: 1.1rem;
              font-style: italic;
              margin: 0;
              white-space: pre-line;
              @media ${screen.small} {
                font-size: 0.88rem;
              }

              :nth-child(2) {
                font-weight: 100;
                font-style: normal;
                margin: 8px 0;
                line-height: 1.35;
              }
            }
          }
        }
      }

      &__link {
        margin: 32px auto 0 auto;

        @media ${screen.medium} {
          margin: 80px auto 0 auto;
        }

        span {
          transform: rotateY(-7deg);
          transition: ${transHover};
          background: ${mainGreen};
          font-size: 1.25rem;
          @media ${screen.small} {
            font-size: 1.5rem;
          }
          @media ${screen.medium} {
            font-size: 1.75rem;
          }
          @media ${screen.xlarge} {
            font-size: 2rem;
          }
        }

        @media ${screen.withCursor} {
          &:hover span {
            background: ${mainWhite};
            color: ${mainGreen};
          }
        }
      }
    }

    &__featured {
      padding: 70px 25px;
      background-image: url(${imgFeaturedBg});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: left;
      position: relative;
      overflow: hidden;
      @media ${screen.small} {
        background-position: center;
        padding: 100px 0;
        background-attachment: fixed;
      }
      @media ${screen.large} {
        overflow: hidden;
      }

      .text-featured {
        margin: 0 auto;
        max-width: ${innerWidth};
        padding-left: 30px;
        padding-right: 30px;
        text-align: center;
        @media ${screen.small} {
          padding-left: 50px;
          padding-right: 50px;
          text-align: left;
        }

        &__heading {
          color: ${mainWhite};
          font-family: ${ccbiffbamboomRegular};
          font-size: 3rem;
          @media ${screen.small} {
            font-size: 4rem;
          }
          @media ${screen.medium} {
            font-size: 5rem;
          }
        }

        &__subheading {
          font-family: ${ccbiffbamboomRegular};
          color: ${mainOrange};
          font-size: 1.5rem;
          max-width: 550px;
          margin: 15px 0;
          @media ${screen.small} {
            font-size: 1.8rem;
            max-width: 480px;
            margin: 15px 0;
          }
          @media ${screen.medium} {
            font-size: 2rem;
            max-width: 550px;
          }
        }

        &__description {
          max-width: 700px;
          position: relative;
          z-index: 2;

          p {
            color: ${mainWhite};
            font-weight: 700;
            font-style: italic;
            font-size: 1.2rem;
            margin: 0 0 38px 0;
            @media ${screen.small} {
              font-size: 1.4rem;
            }
            @media ${screen.medium} {
              font-size: 1.55rem;
            }
          }

          a {
            color: ${mainWhite};
            text-decoration: underline;
            transition: ${transHover};
            @media ${screen.withCursor} {
              &:hover {
                color: ${mainGreen};
              }
            }
          }
        }

        &__link {
          margin-top: 25px;
          display: inline-block;
          margin-left: -25px;

          span {
            transform: rotateY(-7deg);
            transition: ${transHover};
            font-size: 1.88rem;
            @media ${screen.small} {
              font-size: 2.35rem;
            }
            @media ${screen.medium} {
              font-size: 2rem;
            }
            @media ${screen.xlarge} {
              font-size: 3rem;
            }
          }

          @media ${screen.withCursor} {
            &:hover span {
              background: ${mainWhite};
              color: ${mainOrange};
            }
          }
        }
      }

      .img-fixed {
        pointer-events: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        max-width: 440px;
        display: none;
        z-index: 1;
        @media ${screen.large} {
          width: 100%;
          display: block;
          max-width: calc(300px + 32vw);
          left: calc(100px + 42vw);
        }
        @media ${screen.xlarge} {
          left: 800px;
          max-width: clamp(100px, 500px + 25vw, 1100px);
        }
      }
    }

    &__history {
      overflow: hidden;

      .flex-inner {
        margin: 0 auto;
        max-width: ${innerWidth};
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 20px;
        padding-bottom: 70px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        position: relative;
        @media ${screen.small} {
          padding-left: 50px;
          padding-right: 50px;
        }
        @media ${screen.medium} {
          flex-direction: row;
        }
        @media ${screen.large} {
          padding-bottom: 0;
          padding-top: 0;
        }

        &__img {
          margin-left: -34px;
          @media ${screen.small} {
            margin-left: -100px;
            margin-right: 10px;
          }
          @media ${screen.medium} {
            margin-left: -100px;
            margin-right: 20px;
          }

          .portrait {
            position: relative;
            max-width: 450px;
            pointer-events: none;
            margin: 0 auto;
            @media ${screen.small} {
              max-width: 750px;
            }
            @media ${screen.medium} {
              max-width: 550px;
            }
            @media ${screen.large} {
              max-width: 750px;
            }

            .main {
              width: 450px;
              @media ${screen.small} {
                width: 750px;
              }
              @media ${screen.medium} {
                width: 550px;
              }
              @media ${screen.large} {
                width: 750px;
              }
            }

            .mouth {
              animation: ${moveMouth} 5s ease-in 0s infinite both;
              position: absolute;
              left: 233px;
              top: 185px;
              width: 32px;
              pointer-events: none;
              @media ${screen.small} {
                left: 308px;
                top: 248px;
                width: 48px;
                left: 385px;
                top: 310px;
                width: 58px;
              }
              @media ${screen.medium} {
                left: 285px;
                top: 228px;
                width: 38px;
              }
              @media ${screen.large} {
                left: 385px;
                top: 310px;
                width: 58px;
              }
            }
          }

          .bubble {
            max-width: 140px;
            width: 100%;
            position: absolute;
            top: 60px;
            left: auto;
            right: 5vw;
            z-index: 9;
            pointer-events: none;
            @media ${screen.small} {
              max-width: 340px;
              left: calc(10px + 52vw);
              left: auto;
              right: -7vw;
              top: 16px;
            }
            @media ${screen.medium} {
              max-width: 220px;
              left: 345px;
              right: auto;
              top: 30px;
            }
            @media ${screen.large} {
              max-width: 290px;
              top: 60px;
              left: 474px;
            }
          }
        }

        &__text {
          text-align: center;
          margin-top: -45px;
          @media ${screen.medium} {
            text-align: right;
            margin-top: 0;
          }

          h2 {
            font-family: ${ccbiffbamboomRegular};
            color: ${mainOrange};
            font-size: 2.1rem;
            margin: 25px 0;
            @media ${screen.small} {
              font-size: 2.2rem;
            }
            @media ${screen.medium} {
              font-size: 2.6rem;
            }

            strong {
              color: ${darkBlue};
              font-size: 2.1rem;
              @media ${screen.small} {
                font-size: 4rem;
              }
              @media ${screen.medium} {
                font-size: 5rem;
              }
            }

            .text-break {
              @media ${screen.small} {
                display: block;
              }
            }
          }

          p {
            color: ${darkBlue};
            font-weight: 400;
            font-size: 1.2rem;
            font-weight: 700;
            font-style: italic;
            margin: 15px 0;
            @media ${screen.small} {
              font-size: 1.4rem;
            }
            @media ${screen.medium} {
              font-size: 1.55rem;
            }
          }

          a {
            margin-right: 30px;
            @media ${screen.small} {
              margin-right: 30px;
            }
            @media ${screen.xlarge} {
              margin-right: 40px;
            }

            span {
              transform: rotateY(-7deg);
              transition: ${transHover};
              background: ${mainGreen};
              font-size: 1.75rem;
              @media ${screen.small} {
                font-size: 2.35rem;
              }
              @media ${screen.medium} {
                font-size: 2rem;
              }
              @media ${screen.xlarge} {
                font-size: 3rem;
              }
            }

            @media ${screen.withCursor} {
              &:hover span {
                background: ${mainWhite};
                color: ${mainGreen};
              }
            }
          }
        }
      }
    }

    &__newsletter {
      background-color: ${mainOrange};
      background-image: url(${imgOrange});
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      padding: 80px 0;

      .flex-inner {
        display: flex;
        flex-direction: column;
        max-width: ${innerWidth};
        padding-left: 30px;
        padding-right: 30px;
        margin: 0 auto;

        @media ${screen.small} {
          padding-left: 50px;
          padding-right: 50px;
          flex-direction: row;
          justify-content: space-between;
        }

        h5 {
          color: ${mainWhite};
          font-style: italic;
          font-size: 1.2rem;
          text-align: center;
          margin-bottom: 25px;
          max-width: 600px;
          @media ${screen.small} {
            font-size: 1.4rem;
            text-align: left;
            margin-bottom: 0;
          }
          @media ${screen.medium} {
            font-size: 1.55rem;
          }

          @media ${screen.xlarge} {
            font-size: 1.75rem;
          }
        }

        form {
          max-width: 650px;

          .row {
            display: flex;
            flex-direction: row;
            position: relative;
            margin-top: 15px;
            @media ${screen.small} {
              margin-left: 30px;
            }
            @media ${screen.large} {
              margin-left: 0;
            }

            &--bot {
              flex-direction: column;
              @media ${screen.small} {
                flex-direction: row;
              }
            }

            .input {
              border: 0;
              border-radius: 0;
              font-size: 1.37rem;
              border-radius: none;
              width: 100%;
              padding: 7px 25px;
              outline-color: ${darkBlue};
              @media ${screen.small} {
                width: 250px;
              }
              @media ${screen.medium} {
                width: 380px;
              }

              ::placeholder {
                color: #e2e2e3;
              }

              &--half {
                width: 50%;
              }

              &--full {
                width: 100%;
              }
            }

            button {
              border: 0;
              background: ${darkBlue};
              cursor: pointer;
              color: ${mainWhite};
              font-family: ${montserrat};
              font-weight: 600;
              font-size: 1.3rem;
              padding: 7px 25px;
              max-width: 180px;
              width: 100%;
              margin: 15px auto 0 auto;
              outline-color: ${mainOrange};
              transition: ${transHover};
              @media ${screen.small} {
                max-width: none;
                width: auto;
                margin: 0;
              }
              @media ${screen.withCursor} {
                &:hover {
                  color: ${mainOrange};
                }
              }
            }
            .submit-feedback {
              color: ${mainGreen};
              background: ${mainWhite};
              position: absolute;
              display: block;
              bottom: -40px;
              padding: 2px 8px;
              font-weight: 500;
              color: crimson;
              left: 50%;
              white-space: pre;
              transform: translateX(-50%);
              @media ${screen.small} {
                left: auto;
                transform: translateX(0);
              }
            }
          }
        }
      }
    }

    &__sponsors {
      padding: 70px 0;
      @media ${screen.small} {
        padding: 125px 0;
      }

      .sponsors-inner {
        margin: 0 auto;
        max-width: ${innerWidth};
        padding-left: 30px;
        padding-right: 30px;
        @media ${screen.small} {
          padding-left: 50px;
          padding-right: 50px;
        }

        &__heading {
          color: ${darkBlue};
          text-align: center;
          font-family: ${ccbiffbamboomRegular};
          font-size: 2.1rem;
          @media ${screen.small} {
            font-size: 1.75rem;
          }

          strong {
            font-size: 2.1rem;
            @media ${screen.small} {
              font-size: 3.3rem;
              display: block;
            }
          }
        }

        &__list {
          display: flex;
          flex-wrap: wrap;
          margin: 15px -20px 0 -20px;
          justify-content: center;
          @media ${screen.small} {
            margin: 15px -30px 0 -30px;
          }
          li {
            width: calc(50% - 40px);
            margin: 20px;
            @media ${screen.small} {
              margin: 30px;
              width: calc(33.33% - 60px);
            }
            @media ${screen.medium} {
              width: calc(25% - 60px);
            }
          }
        }
      }
    }
  }
`;

const IndexPage = ({ data }) => {
  const [submitFeedback, setSubmitFeedback] = useState("");
  const [userInput, setUserInput] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  const {
    banner_heading,
    banner_cta_link,
    banner_cta_label,
    banner_description,
    banner_background_image_large,
    banner_background_image_small,
    about_heading,
    about_description,
    featured_heading,
    featured_subheading,
    featured_description1,
    sponsor_list,
    title_tag,
    meta_description,
    stream_section_anchor,
    stream_visible,
    stream_heading,
    stream_video,
    stream_subheading,
    stream_playwrights = [],
    stream_button_link,
    stream_button_label,
  } = data.content.data;

  const handleChange = e => {
    userInput[e.target.name] = e.target.value;

    setUserInput({ ...userInput });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const { email, firstName, lastName } = userInput;

    if (!emailRegex.test(email)) {
      setSubmitFeedback("INVALID EMAIL ADDRESS");
      return;
    }

    try {
      const result = await addToMailchimp(email, {
        FNAME: firstName,
        LNAME: lastName,
      });

      if (result) {
        setSubmitFeedback("THANK YOU FOR SUBMISSION!");
        setUserInput({
          firstName: "",
          lastName: "",
          email: "",
        });
      }
    } catch (e) {
      setSubmitFeedback("SORRY SOMETHING WENT WRONG");
      console.error(e);
    }
  };

  return (
    <Layout>
      <Wrapper
        imgBannerSmall={banner_background_image_small.url}
        imgBannerLarge={banner_background_image_large.url}
      >
        <SEO title={title_tag} description={meta_description} />
        <div className="home">
          <div className="home__banner">
            <div className="text-banner">
              <h1 className="text-banner__heading">{banner_heading.text}</h1>
              <p className="text-banner__description">{banner_description}</p>
              {banner_cta_link.url && (
                <a
                  className="text-banner__link"
                  href={banner_cta_link.url}
                  target={banner_cta_link.target}
                >
                  <SlopedLabel text={banner_cta_label} />
                </a>
              )}
            </div>
          </div>

          {stream_visible !== true ? (
            <section className="home__introduction">
              <div className="text-intro">
                <h2 className="text-intro__heading">{about_heading}</h2>
                <div
                  className="text-intro__description"
                  dangerouslySetInnerHTML={{ __html: about_description.html }}
                />
              </div>
            </section>
          ) : (
            <section id={stream_section_anchor} className="home__stream">
              <h2 className="home__stream__heading">{stream_heading?.text}</h2>

              <div className="home__stream__watching">
                <iframe
                  src={stream_video?.url}
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                />
              </div>

              <div className="home__stream__meeting">
                <p>{stream_subheading}</p>
                <div className="home__stream__meeting__items">
                  {stream_playwrights?.map((playwright, index) => (
                    <div key={index}>
                      <div>
                        <img
                          src={playwright?.playwright_image?.url}
                          alt="avatar"
                        />
                      </div>
                      <p>{playwright?.playwright_name}</p>
                      <p>{playwright?.playwright_description}</p>
                    </div>
                  ))}
                </div>
              </div>

              <a
                className="home__stream__link"
                href={stream_button_link?.url}
                target={stream_button_link?.target}
              >
                <SlopedLabel text={stream_button_label} />
              </a>
            </section>
          )}

          <section className="home__featured">
            <div className="text-featured">
              <h2 className="text-featured__heading">{featured_heading}</h2>
              <h5 className="text-featured__subheading">
                {featured_subheading}
              </h5>
              <div
                className="text-featured__description"
                dangerouslySetInnerHTML={{ __html: featured_description1.html }}
              />
            </div>

            <img
              className="img-fixed"
              src={imgFeaturedFixed}
              alt="Participants"
            />
          </section>

          <section className="home__history">
            <div className="flex-inner">
              <ScrollAnimation
                className="flex-inner__img"
                animateIn="fadeInLeft"
                animateOnce={true}
              >
                <div className="portrait">
                  <img className="main" src={portrait} alt="James Martin" />
                  <img
                    className="mouth"
                    src={portraitMouth}
                    alt="James Martin"
                  />
                </div>

                <img
                  className="bubble"
                  src={imgSpeechBubble}
                  alt="Speech Bubble"
                />
              </ScrollAnimation>
              <div className="flex-inner__text">
                <h2>
                  <span className="text-break">LEARN ALL ABOUT</span>{" "}
                  <strong>THE HISTORY</strong>OF THE
                  <span className="text-break"></span> LYSICRATES FOUNDATION
                </h2>
                <p>
                  Find out about the origin and inspiration behind the annual
                  playwriting competition Lysicrates Prize.
                </p>
                <a
                  href="https://lysicratesfoundation.org.au/james-martin"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SlopedLabel text="VISIT WEBSITE" />
                </a>
              </div>
            </div>
          </section>

          <section className="home__newsletter">
            <div className="flex-inner">
              <h5>For more details please enter your email address here</h5>
              <form
                onFocus={() => setSubmitFeedback("")}
                onSubmit={handleSubmit}
              >
                <p className="row">
                  <input
                    className="input input--half"
                    onChange={handleChange}
                    type="text"
                    placeholder="First Name"
                    name="firstName"
                    value={userInput.firstName}
                    required
                  />
                  &nbsp;&nbsp;&nbsp;
                  <input
                    className="input input--half"
                    onChange={handleChange}
                    type="text"
                    placeholder="Last Name"
                    name="lastName"
                    value={userInput.lastName}
                    required
                  />
                </p>
                <p className="row row--bot">
                  <input
                    className="input input--full"
                    onChange={handleChange}
                    placeholder="Email Address*"
                    type="email"
                    name="email"
                    value={userInput.email}
                  />
                  <button>Submit</button>
                  {submitFeedback && (
                    <span
                      className="submit-feedback"
                      style={{
                        color:
                          submitFeedback.indexOf("INVALID") === -1
                            ? mainGreen
                            : "crimson",
                      }}
                    >
                      {submitFeedback}
                    </span>
                  )}
                </p>
              </form>
            </div>
          </section>

          <section className="home__sponsors">
            <div className="sponsors-inner">
              <h2 className="sponsors-inner__heading">
                The martin-Lysicrates prize is{" "}
                <strong>Proudly supported by</strong>
              </h2>
              <ul className="sponsors-inner__list">
                {sponsor_list.map((item, i) => (
                  <li key={i}>
                    <a
                      href={item.website_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.logo.gatsbyImageData && (
                        <GatsbyImage
                          image={item.logo.gatsbyImageData}
                          alt={item.logo.alt || "Sponsor brand"}
                        />
                      )}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </section>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default IndexPage;

export const dataQuery = graphql`
  {
    content: prismicHomePage {
      data {
        banner_heading {
          text
        }
        banner_description
        banner_cta_link {
          url
          link_type
          target
        }
        banner_cta_label
        banner_background_image_large {
          url
        }
        banner_background_image_small {
          url
        }
        about_heading
        about_description {
          html
        }
        featured_heading
        featured_subheading
        featured_description1 {
          html
        }
        sponsor_list {
          website_url
          logo {
            alt
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        stream_section_anchor
        stream_visible
        stream_heading {
          text
        }
        stream_video {
          url
        }
        stream_subheading
        stream_playwrights {
          playwright_image {
            alt
            copyright
            url
            gatsbyImageData
          }
          playwright_description
          playwright_name
        }
        stream_button_link {
          url
        }
        stream_button_label
        title_tag
        meta_description
      }
    }
    latestEvent: allPrismicEventYear(
      sort: { order: DESC, fields: data___title___text }
      limit: 1
    ) {
      edges {
        node {
          data {
            title {
              text
            }
          }
        }
      }
    }
    latestTheWalk: allPrismicTheWalkYear(
      sort: { order: DESC, fields: data___title___text }
      limit: 1
    ) {
      edges {
        node {
          data {
            title {
              text
            }
          }
        }
      }
    }
  }
`;
